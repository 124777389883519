import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-confirm-email-message',
  templateUrl: './confirm-email-message.component.html',
  styleUrls: ['./confirm-email-message.component.scss']
})
export class ConfirmEmailMessageComponent implements OnInit {
  @Input() email: string;
  @Input() type: 'register' | 'changePassword';
  display: string = '';
  public timerInterval: any;
  token: string = '';
  sendSuccessfully: boolean = false;
  registrationForm: UntypedFormGroup;
  @Output() tokenValidated = new EventEmitter();
  constructor(
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private toast: ToastrService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.registrationForm = this.fb.group({
      number1: [null, [Validators.required]],
      number2: [null, [Validators.required]],
      number3: [null, [Validators.required]],
      number4: [null, [Validators.required]],
      number5: [null, [Validators.required]],
      number6: [null, [Validators.required]]
    });
  }

  resendEmail() {
    if (this.type === 'register') {
      this.resendRegisterToken();
    } else {
      this.requestChangePassword();
    }
  }

  resendRegisterToken(): void {
    this.spinner.show();
    this.authService.resendRegisterToken(this.email).subscribe((res) => {
      this.spinner.hide();
      this.timer(1);
      this.toastrService.success(
        'Novo código de acesso enviado para seu e-mail.'
      );
    });
  }

  requestChangePassword(): void {
    this.authService.requestChangePassword(this.email).subscribe(
      () => {
        this.toastrService.success(
          'E-mail enviado com sucesso. Verifique seu spam.'
        );
        this.sendSuccessfully = true;
      },
      (error) => {
        this.toastrService.error('Token invalido.', 'Erro');
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  continue() {
    this.token =
      this.registrationForm.get('number1')?.value +
      this.registrationForm.get('number2')?.value +
      this.registrationForm.get('number3')?.value +
      this.registrationForm.get('number4')?.value +
      this.registrationForm.get('number5')?.value +
      this.registrationForm.get('number6')?.value;

    this.authService.validateRegisterToken(this.token).subscribe((res) => {
      this.tokenValidated.emit();
    });
  }

  timer(minute: number) {
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `Envie novamente em ${textSec} segundos`;

      if (seconds == 0) {
        this.display = '';
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  onDigitInput(event: any) {
    let element;
    if (event.code != 'Backspace' && event.code != 8)
      element = event.srcElement.nextElementSibling;

    if (event.code == 'Backspace' || event.code == 8)
      element = event.srcElement.previousElementSibling;

    if (element == null) return;
    else element.focus();
  }

  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.registrationForm.setValue({
      number1: pastedText.charAt(0),
      number2: pastedText.charAt(1),
      number3: pastedText.charAt(2),
      number4: pastedText.charAt(3),
      number5: pastedText.charAt(4),
      number6: pastedText.charAt(5)
    });
  }
}
