import { Component, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-establishment',
  templateUrl: './delete-establishment.component.html',
  styleUrls: ['./delete-establishment.component.scss']
})
export class DeleteEstablishmentComponent {
  @Input() title: string;
  message =
    'Ao excluir o estabelecimento, não será possível recuperar as informações. Deseja continunar?';
  buttonText = 'Sim, excluir';
  onActionButton = new EventEmitter<boolean>();
  onClosed = new EventEmitter<boolean>();

  constructor(private modalRef: BsModalRef) {}

  confirm(): void {
    this.onActionButton.next(true);
    this.modalRef.hide();
  }

  closeModal(): void {
    this.modalRef.hide();
    this.onClosed.next(true);
  }
}
