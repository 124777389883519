import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CrossDomainSharingService {
  constructor(
    private authService: AuthenticationService,
    private cookieService: CookieService
  ) {}

  postCrossDomainMessage() {
    let json: string = '';

    if (!this.authService.isLoggedIn()) {
      this.cookieService.set('userLogged', json, 10, '/', environment.domain);
      return;
    }

    this.authService.loadUserById().subscribe((user) => {
      let isStudent = 'false';
      if (user != null && this.authService.isLoggedIn()) {
        const data: string[] = [];
        data.push(user.nome);
        if (user.pictureUrl) data.push(user.pictureUrl);
        json = data.join(';');
        isStudent = `${user.estudante}`;
      }

      setTimeout(() => {
        this.cookieService.set('userLogged', json, 10, '/', environment.domain);
        this.cookieService.set(
          'isStudent',
          isStudent,
          10,
          '/',
          environment.domain
        );
      }, 500);
    });
  }
}
