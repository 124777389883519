import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ValidateCustomEmail } from '../../shared/validators/type-validators';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  recoverPasswordForm: UntypedFormGroup;
  requestChangeSuccessfully = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.inicializarFormulario();
  }

  inicializarFormulario() {
    this.recoverPasswordForm = this.fb.group({
      email: [null, [Validators.required, ValidateCustomEmail]]
    });
  }

  onSubmit() {
    if (this.recoverPasswordForm.invalid) {
      this.recoverPasswordForm.markAllAsTouched();
      return;
    }

    this.recoverPassword();
  }

  recoverPassword() {
    this.spinner.show();

    this.authenticationService
      .requestChangePassword(this.recoverPasswordForm.get('email')?.value)
      .subscribe(() => {
        this.toastrService.success(
          'Solicitação de recuperação de senha enviada com sucesso!'
        );
        this.requestChangeSuccessfully = true;
        this.spinner.hide();
      });
  }
}
