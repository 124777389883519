import { Component, Input, OnInit } from '@angular/core';
import { isPast } from 'date-fns';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-registration-update-card',
  templateUrl: './registration-update-card.component.html',
  styleUrl: './registration-update-card.component.scss'
})
export class RegistrationUpdateCardComponent implements OnInit {
  @Input() componentLocation:
    | 'myProfileScreen'
    | 'viewMoreScreenMobile'
    | 'headerDesktop'
    | '' = '';

  isDesktop = false;
  showRegistrationUpdateCard = false;
  showRegistrationUpdateModal = false;

  constructor(
    public detectorDevice: DeviceDetectorService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.checkDevice();
    this.checkStudentGraduationDate();
  }

  checkDevice() {
    this.isDesktop = this.detectorDevice.isDesktop();
  }

  checkStudentGraduationDate(): void {
    this.authService.getUser().subscribe((user) => {
      if (user.estudante) {
        const graduationDate = new Date(
          user.anoFormatura,
          user.mesFormatura - 1,
          1
        );
        this.showRegistrationUpdateCard =
          user.estudante && isPast(graduationDate);
      } else {
        this.showRegistrationUpdateCard = false;
      }
    });
  }

  toggleRegistrationUpdateModalVisibility() {
    this.showRegistrationUpdateModal = !this.showRegistrationUpdateModal;
  }
}
