@if (showStagesDisease) {
  <div class="container-field">
    <field-validate [fieldName]="label" [showSuccessBackground]="false">
      <select
        data-test-id="desktop-select"
        [(ngModel)]="stageDiseaseId"
        [required]="true"
        (ngModelChange)="handleOptionSelected()"
      >
        <option [ngValue]="null">Selecione</option>
        @for (stage of stagesDiseases; track stage) {
          <option [ngValue]="stage.id">
            <span> {{ stage.name }}</span>
          </option>
        }
      </select>
    </field-validate>
  </div>
}
