import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppListResponse, AppResponse } from '../shared/app.response';
import { EmailVerificationModel } from './models/email-verification-model';
import { RegisterPasswordModel } from './models/register-password-model';
import { UserModel } from './models/userModel';
import jwt_decode from 'jwt-decode';
import { RegisterAccountModel } from './models/register-account-model';
import { Router } from '@angular/router';
import { LoginRequest } from './models/login.request';
import { LoginResponse } from './models/login.response';
import { CurrentUser } from './models/current-user';
import { PasswordVerificationModel } from './models/password-verification-model';
import { ChangePasswordModel } from './models/change-password-model';
import { LoadingService } from '../shared/loading.service';
import { ChangeEmailModel } from './models/change-email-model';
import { BehaviorSubject } from 'rxjs';
import { UserDataModel } from './models/user-data.model';
import { CompleteRegisterModel } from './models/complete-register.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private apiUrlAuthentication = `${environment.baseApimUrl}/authentication`;
  private apiUrlVeterinario = `${environment.baseApimUrl}/veterinario`;
  private userSubject = new BehaviorSubject<UserModel>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  getUser(): Observable<UserModel> {
    return this.userSubject.asObservable();
  }

  setUser(user: UserModel): void {
    localStorage.setItem('User', btoa(JSON.stringify(user)));
    this.userSubject.next(user);
  }

  loadUserById(): Observable<UserModel> {
    const headers = this.loadingService.getLoadingHeader();
    const url = `${this.apiUrlVeterinario}/list`;
    const searchObject = {
      SearchExpression: '',
      Filters: [
        {
          Key: 'id',
          Operator: 'Equals',
          Value: this.getUserId()
        }
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: 0,
      PageSize: 1
    };

    return this.http
      .post<AppListResponse<UserModel>>(url, searchObject, { headers })
      .pipe(map((r) => (r.items.length ? r.items[0] : null)));
  }

  getUserId(): number {
    const currentUser = this.getUserInformation();
    return currentUser.userId;
  }

  registerPassword(
    registerPasswordRequest: RegisterPasswordModel
  ): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/password/create`;
    return this.http.post<boolean>(url, registerPasswordRequest);
  }

  registerAccount(
    registerAccountRequest: RegisterAccountModel
  ): Observable<RegisterAccountModel> {
    const url = `${this.apiUrlAuthentication}/register`;
    return this.http.post<RegisterAccountModel>(url, registerAccountRequest);
  }

  validateEmail(request: EmailVerificationModel): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/mail/validate`;
    return this.http.post<boolean>(url, request);
  }

  verificationEmail(request: EmailVerificationModel): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/mail/verification`;
    return this.http.post<boolean>(url, request);
  }

  validatePassword(request: PasswordVerificationModel): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/password/validate`;
    return this.http.post<boolean>(url, request);
  }

  requestChangePassword(email: string): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/password/request-change`;
    return this.http.post<boolean>(url, { email });
  }

  changePassword(
    changePasswordRequest: ChangePasswordModel
  ): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/password/update`;
    return this.http.post<boolean>(url, changePasswordRequest);
  }

  login(request: LoginRequest): Observable<LoginResponse> {
    const headers = this.loadingService.getLoadingHeader();
    return this.http.post<LoginResponse>(
      `${this.apiUrlAuthentication}/login`,
      request,
      {
        headers
      }
    );
  }

  completeRegister(request: CompleteRegisterModel): Observable<void> {
    const headers = this.loadingService.getLoadingHeader();
    return this.http.post<void>(
      `${this.apiUrlAuthentication}/complete`,
      request,
      {
        headers
      }
    );
  }

  async setLoginItems(login: LoginResponse): Promise<boolean> {
    localStorage.setItem('userToken', login.accessToken);
    localStorage.setItem('hasPendency', login.hasChangePassword);
    void this.router.navigate(['/']);
    return true;
  }

  logoff(): void {
    localStorage.removeItem('userToken');
    void this.router.navigate(['/login']);
  }

  getUserEmail(): string {
    const currentUser = this.getUserInformation();
    return currentUser.email;
  }

  getUserName(): string {
    const currentUser = this.getUserInformation();
    return currentUser.fullName;
  }

  getUserCRMV(): string {
    const currentUser = this.getUserInformation();
    return currentUser.crmv;
  }

  getUserInformation(): CurrentUser {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    const information: CurrentUser = jwt_decode(token);
    information.accessToken = token;
    return information;
  }

  isLoggedIn(): boolean {
    const currentUser = this.getUserInformation();
    if (!currentUser) {
      return false;
    }
    const expiration = new Date(currentUser.exp * 1000);
    return expiration >= new Date();
  }

  getToken(): string {
    const token = localStorage.getItem('userToken');
    if (!token) {
      return null;
    }
    return token;
  }

  updateUser(user: UserModel): Observable<AppResponse<UserModel>> {
    const headers = this.loadingService.getLoadingHeader();
    const url = `${this.apiUrlVeterinario}/${user.id}/update`;
    return this.http.put<AppResponse<UserModel>>(url, user, { headers });
  }

  requestChangeEmail(request: ChangeEmailModel): Observable<boolean> {
    const url = `${this.apiUrlAuthentication}/email/request-change`;
    const headers = this.loadingService.getLoadingHeader();
    return this.http.post<boolean>(url, request, { headers });
  }

  changeEmail(request: ChangeEmailModel): Observable<AppResponse<boolean>> {
    const url = `${this.apiUrlAuthentication}/email/update`;
    const headers = this.loadingService.getLoadingHeader();
    return this.http.post<AppResponse<boolean>>(url, request, { headers });
  }

  getUserData(): Observable<UserDataModel> {
    const headers = this.loadingService.getLoadingHeader();
    const userId = this.getUserId();
    const url = `${this.apiUrlVeterinario}/${userId}/data`;
    return this.http.get<UserDataModel>(url, { headers });
  }

  getActiveRequestChangeEmail(): Observable<EmailVerificationModel> {
    const url = `${this.apiUrlAuthentication}/email/active-request-change`;
    const headers = this.loadingService.getLoadingHeader();
    return this.http.get<EmailVerificationModel>(url, { headers });
  }

  cancelRequestChangeEmail(): Observable<AppResponse<boolean>> {
    const url = `${this.apiUrlAuthentication}/email/cancel-request-change`;
    const headers = this.loadingService.getLoadingHeader();
    return this.http.get<AppResponse<boolean>>(url, { headers });
  }

  resendRequestChangeEmail(): Observable<AppResponse<boolean>> {
    const url = `${this.apiUrlAuthentication}/email/resend-request-change`;
    const headers = this.loadingService.getLoadingHeader();
    return this.http.get<AppResponse<boolean>>(url, { headers });
  }

  validateRegisterToken(registerToken: string): Observable<boolean> {
    const request = {
      token: registerToken
    };
    const url = `${this.apiUrlAuthentication}/token/validate`;
    return this.http.post<boolean>(url, request);
  }

  resendRegisterToken(registerEmail: string) {
    const request = {
      email: registerEmail
    };
    const url = `${this.apiUrlAuthentication}/token/resend`;
    return this.http.post<boolean>(url, request);
  }

  updateStudent(userModel: UserModel) {
    const url = `${this.apiUrlVeterinario}/${userModel.id}/update-student`;
    return this.http.put<AppResponse<UserModel>>(url, userModel);
  }
}
