import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appOverflowHidden]'
})
export class OverflowHiddenDirective {
  @Input() set appOverflowHidden(enable: boolean | undefined) {
    this.setOverflowStyle(enable);
  }
  constructor() {}

  private setOverflowStyle(enable: boolean | undefined): void {
    enable
      ? (document.body.style.overflowY = 'hidden')
      : (document.body.style.overflowY = 'auto');
  }
}
