import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ClinicService } from '../../../../services/clinic/clinic.service';

@Component({
  selector: 'app-upload-clinic-logo',
  templateUrl: './upload-clinic-logo.component.html',
  styleUrl: './upload-clinic-logo.component.scss'
})
export class UploadClinicLogoComponent {
  @ViewChild('inputUpload') upload: ElementRef;
  @Input() logoUrl: string;
  @Output() selectedImageUrl = new EventEmitter<string>();

  maxFileSizeMB = 5;
  error = false;
  constructor(private clinicService: ClinicService) {}
  selectFile(): void {
    this.upload.nativeElement.click();
  }

  uploadFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.clinicService.uploadLogo(file).subscribe(
        (logoUrl) => {
          this.logoUrl = logoUrl;
          this.selectedImageUrl.emit(logoUrl);
        },
        () => (this.error = true)
      );
    }
  }

  removeLogo(): void {
    this.logoUrl = null;
    this.selectedImageUrl.emit(null);
  }
}
