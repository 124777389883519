import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { States } from '../../../services/static/models/states';
import { StaticService } from '../../../services/static/static.service';
import {
  ValidateCustomCpf,
  ValidateOnlyText
} from '../../shared/validators/type-validators';
import { CompleteRegisterModel } from '../../../services/authentication/models/complete-register.model';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification/notification.service';
import { UniversityWithName } from 'src/app/services/static/models/universities';
import { Ga4Service } from 'src/app/services/ga4/ga4.service';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent implements OnInit {
  @Input() email: string;
  listStates: States[] = [];
  registrationForm: FormGroup;
  isMobile = false;
  isStudent = false;
  isOptionSelected: boolean | null = null;
  isStudentSelectedValue = false;
  minDate: string;
  listMonths: { value: number; label: string }[] = [];
  listYears: { value: string; label: string }[] = [];
  universityOptions: { value: string; label: string }[] = [];
  disableUniversitySelect = false;
  infiniteSelectBorderColor = '#ccc';
  infiniteSelectFocusBorderColor = '#666';
  universitySelected = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private staticService: StaticService,
    private notificationService: NotificationService,
    private ga4Service: Ga4Service
  ) {}

  onUniversitySelected(selectedUniversity: UniversityWithName): void {
    this.registrationForm
      .get('university')
      ?.setValue(selectedUniversity.nameFull);
    this.registrationForm.get('university')?.markAsTouched();
    this.universitySelected = !!selectedUniversity;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getListStatesOfBrazil();
    this.isMobile = window.innerWidth <= 900;
    this.setMinDateForFormation();
    this.initializeMonths();
    this.initializeYears();
    this.veterinariaFormPreparation();
  }

  initializeForm(): void {
    this.registrationForm = this.fb.group({
      name: [null, [Validators.required, ValidateOnlyText]],
      email: [null, [Validators.required]],
      crmvNumber: [null, [Validators.required, Validators.minLength(4)]],
      state: [null, [Validators.required]],
      cpf: [null, [ValidateCustomCpf, Validators.required]],
      acceptedTerms: [true, Validators.requiredTrue],
      university: [null, Validators.required],
      graduationMonth: [null, Validators.required],
      graduationYear: [null, Validators.required]
    });

    this.registrationForm.get('email').setValue(this.email);
  }

  initializeMonths(): void {
    const monthNames = [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ'
    ];

    this.listMonths = monthNames.map((month, index) => ({
      value: index + 1,
      label: month
    }));
  }

  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    const totalYears = 10;

    this.listYears = Array.from({ length: totalYears }, (_, i) => {
      const year = currentYear + i;
      return {
        value: year.toString(),
        label: year.toString()
      };
    });
  }

  getListStatesOfBrazil(): void {
    this.staticService.getAllStatesOfBrazil().subscribe((res) => {
      this.listStates = res;
    });
  }

  setMinDateForFormation(): void {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    this.minDate = `${currentYear}-${currentMonth < 10 ? '0' + currentMonth : currentMonth}`;
  }

  veterinariaFormPreparation(): void {
    this.registrationForm
      .get('crmvNumber')
      ?.setValidators([Validators.required, Validators.minLength(4)]);
    this.registrationForm.get('state')?.setValidators([Validators.required]);

    this.registrationForm.get('university')?.clearValidators();
    this.registrationForm.get('graduationMonth')?.clearValidators();
    this.registrationForm.get('graduationYear')?.clearValidators();

    this.registrationForm.get('crmvNumber')?.updateValueAndValidity();
    this.registrationForm.get('state')?.updateValueAndValidity();
    this.registrationForm.get('university')?.updateValueAndValidity();
    this.registrationForm.get('graduationMonth')?.updateValueAndValidity();
    this.registrationForm.get('graduationYear')?.updateValueAndValidity();
  }

  studentFormPreparation(): void {
    this.registrationForm
      .get('university')
      ?.setValidators([Validators.required]);
    this.registrationForm
      .get('graduationMonth')
      ?.setValidators([Validators.required]);
    this.registrationForm
      .get('graduationYear')
      ?.setValidators([Validators.required]);

    this.registrationForm.get('crmvNumber')?.clearValidators();
    this.registrationForm.get('state')?.clearValidators();

    this.registrationForm.get('university')?.updateValueAndValidity();
    this.registrationForm.get('graduationMonth')?.updateValueAndValidity();
    this.registrationForm.get('graduationYear')?.updateValueAndValidity();
    this.registrationForm.get('crmvNumber')?.updateValueAndValidity();
    this.registrationForm.get('state')?.updateValueAndValidity();
  }

  selectVeterinarian(): void {
    console.log('selectVeterinarian');
    this.isOptionSelected = true;
    this.isStudent = false;
    this.veterinariaFormPreparation();
  }
  selectStudent(): void {
    console.log('selectStudent');
    this.isOptionSelected = true;
    this.isStudent = true;
    this.studentFormPreparation();
  }

  submit(): void {
    console.log('submit', this.registrationForm);
    if (this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();
      this.notificationService.error('Preencha todos os campos obrigatórios.');
      return;
    }

    this.completeRegister();
  }

  continue(): void {
    if (this.isStudentSelectedValue) {
      this.selectStudent();
      return;
    }

    this.selectVeterinarian();
  }

  completeRegister(): void {
    const request = this.registerRequest;

    this.authService.completeRegister(request).subscribe(
      () => {
        this.notificationService.success('Cadastro realizado com sucesso!');
        if (this.isStudent) {
          this.ga4Service.emit('cadastro_sucesso_estudante_login');
          void this.router.navigate(['cadastro_sucesso_estudante_login']);
          return;
        }

        this.ga4Service.emit('cadastro_sucesso_login');
        void this.router.navigate(['cadastro_sucesso_login']);
      },
      (error) => {
        console.error('Erro ao realizar cadastro:', error);
      }
    );
  }

  get registerRequest(): CompleteRegisterModel {
    if (this.isStudent) {
      return {
        nome: this.registrationForm.get('name').value,
        email: this.registrationForm.get('email').value,
        cpf: this.registrationForm.get('cpf').value,
        aceiteTermos: this.registrationForm.get('acceptedTerms').value,
        universidade: this.registrationForm.get('university').value,
        mesFormatura: this.registrationForm.get('graduationMonth').value,
        anoFormatura: this.registrationForm.get('graduationYear').value,
        estudante: this.isStudent,
        specialties: [32]
      };
    } else {
      return {
        nome: this.registrationForm.get('name').value,
        email: this.registrationForm.get('email').value,
        numeroCrmv: this.registrationForm.get('crmvNumber').value,
        estadoCrmv: this.registrationForm.get('state').value,
        cpf: this.registrationForm.get('cpf').value,
        aceiteTermos: this.registrationForm.get('acceptedTerms').value,
        estudante: this.isStudent
      };
    }
  }
}
