<app-infinite-select
  (searchChange)="filterUniversitiesSubject.next($event)"
  (scrollToEnd)="filterUniversitiesSubject.next($event)"
  (optionSelected)="onUniversitySelected($event)"
  [createdValue]="universitySelected"
  [options]="universities"
  [required]="true"
  optionTitle="nameFull"
  label="Universidade"
  [inputValue]="universitySelected?.name"
  [disabledButton]="universitySelected !== null"
  [loading]="loading"
  placeholder="Pesquise pelo nome da universidade"
  [actionButton]="false"
>
</app-infinite-select>
