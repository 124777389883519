<main
  [ngClass]="
    showContact ? 'footer-container' : 'footer-container-without-contact'
  "
>
  <div *ngIf="showContact">
    <section class="info-footer row">
      <div class="col logo-container">
        <caption class="caption-info">
          Programa Parceiro
        </caption>
        <img [src]="imgLogo" width="90px" alt="logo PremieRvet®" />
        <caption class="caption-info">
          Inscreva-se
          <a [href]="urlPartnerProgam" target="_blank">aqui</a>
          é grátis!
        </caption>
      </div>
      <div class="col contact-container">
        <section class="icon-container">
          <i class="bi bi-envelope icon-footer"></i>
        </section>
        <div class="without-link">
          <p class="info-title">CONTATO</p>
          <a href="mailto:contato@premiervet.com.br">
            <p class="info-title -subtitle" role="button">
              contato&#64;premiervet.com.br
            </p></a
          >
        </div>
      </div>
      <div class="col phone-container">
        <section class="icon-container">
          <i class="bi bi-telephone icon-footer"></i>
        </section>
        <div class="without-link">
          <a href="tel:+0800-055-6666">
            <p class="info-title" role="button">0800-055-6666</p>
          </a>
          <p class="info-title -subtitle">De 2ª à 6ª das 8h30 às 17h30</p>
        </div>
      </div>
      <div class="col privacy-container without-link">
        <section class="icon-container">
          <i class="bi bi-shield-check icon-footer"></i>
        </section>
        <a [href]="urlTermsPdf" target="_blank">
          <p class="info-title" role="button">Política de Privacidade</p>
        </a>
      </div>
    </section>
    <hr class="w-100" />
  </div>
  <section class="copy-write">
    <p class="info-title">
      &#64;{{ year }} PremieRvet®. Todos os direitos reservados.
    </p>
  </section>
</main>
