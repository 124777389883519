<div
  [ngClass]="{
    'credentials-container': !mobileScreen,
    'credentials-container-mobile': mobileScreen,
    'background-login': mobileScreen && isLoginScreen,
    'background-default': mobileScreen && !isLoginScreen
  }"
>
  <div
    [ngClass]="isLoginScreen ? 'logo-mobile-woman' : 'logo-mobile-premier'"
    *ngIf="mobileScreen"
  >
    <button
      [ngClass]="isLoginScreen ? 'mt-16' : 'mt-6'"
      type="button"
      class="button-back-mobile"
      *ngIf="mobileScreen && !hideMobileBackButton"
      (click)="getNavigate()"
    >
      <i class="bi bi-arrow-left-short"></i>
    </button>
    <img
      *ngIf="isLoginScreen"
      class="logo-woman"
      src="../../../../../assets/images/logo-login-mobile.webp"
    />
    <img *ngIf="!isLoginScreen" class="logo-premier" [src]="pathImg" />
  </div>
  <div class="logo" *ngIf="!mobileScreen">
    <img [src]="pathImg" alt="logo-premier" (click)="getNavigate()" />
  </div>
  <section class="form">
    <ng-content></ng-content>
  </section>
</div>
