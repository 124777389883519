<div *ngIf="showRegistrationUpdateCard">
  <div
    [ngClass]="[componentLocation, isDesktop ? 'desktop-view' : 'mobile-view']"
    class="registration-update-card"
  >
    <div>
      <p>Se formou e não é mais um estudante?</p>
      <a
        class="dropdown-item"
        (click)="toggleRegistrationUpdateModalVisibility()"
        >Atualize o seu cadastro</a
      >
    </div>
    <i class="bi bi-mortarboard"></i>
  </div>
</div>

<app-registration-update-modal
  [isDesktop]="isDesktop"
  [isVisible]="showRegistrationUpdateModal"
  [onClose]="toggleRegistrationUpdateModalVisibility"
></app-registration-update-modal>
