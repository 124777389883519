<main class="modal-container offcanvas mb-2">
  <div class="modal-header">
    <div class="product-box">
      <div class="title-box">
        <h5 class="modal-title">Informações nutricionais</h5>
        <button
          type="button"
          class="close pull-right"
          (click)="close()"
          aria-label="Fechar"
        >
          <i class="bi bi-x icon__close"></i>
        </button>
      </div>
      <div class="product-detail">
        <img
          class="product-img"
          alt="product-img"
          src="https://sapremiervetprd01.blob.core.windows.net/public-images/3026121.png"
        />
        <div class="product-title">
          <p class="product-type">ALIMENTO SECO</p>
          <p class="product-name">
            PremieR Nutrição Clínica | Obesidade | Adultos | Porte Pequeno
          </p>
        </div>
      </div>
      <div class="tabs-box">
        <span
          class="{{ tabIndex === 0 ? 'active' : '' }}"
          (click)="changeTab(0)"
          >Geral</span
        >
        <span
          class="{{ tabIndex === 1 ? 'active' : '' }}"
          (click)="changeTab(1)"
          >Níveis de Garantia</span
        >
        <span
          class="{{ tabIndex === 2 ? 'active' : '' }}"
          (click)="changeTab(2)"
          >Composição</span
        >
        <span
          class="{{ tabIndex === 3 ? 'active' : '' }}"
          (click)="changeTab(3)"
          >Enriquecimento</span
        >
      </div>
      <div class="tab-content pb-4">
        <div class="geral" *ngIf="tabIndex == 0">
          <h5 class="tab-title">INDICAÇÃO</h5>
          <div class="geral-content">
            <p>
              PremieR® Nutrição Clínica Cães Obesidade é um alimento auxiliar
              ao tratamento da obesidade em cães adultos de porte pequeno.
            </p>
            <p>
              Além do tratamento prescrito pelo médico-veterinário, a alteração
              na alimentação é fator primordial para o tratamento da obesidade.
            </p>
            <p>
              Os objetivos da dieta são: promover perda de peso efetiva e
              saudável, com manutenção da massa magra e suporte articular.
            </p>
            <p>
              Para obesidade, este alimento deve ser utilizado sob orientação e
              prescrição de um profissional, até que o animal atinja o peso
              ideal.
            </p>
          </div>
          <div class="more-information">
            <h5 class="tab-title">BENEFÍCIOS</h5>
            <div class="more-information-item">
              <p class="information-title">Calorias Reduzidas</p>
              <p class="information-description">
                Conteúdo nutricional cientificamente balanceado e adequado à
                baixa ingestão calórica.
              </p>
            </div>
            <div class="more-information-item">
              <p class="information-title">Calorias Reduzidas</p>
              <p class="information-description">
                Conteúdo nutricional cientificamente balanceado e adequado à
                baixa ingestão calórica.
              </p>
            </div>
            <div class="more-information-item">
              <p class="information-title">Calorias Reduzidas</p>
              <p class="information-description">
                Conteúdo nutricional cientificamente balanceado e adequado à
                baixa ingestão calórica.
              </p>
            </div>
            <div class="more-information-item">
              <p class="information-title">Calorias Reduzidas</p>
              <p class="information-description">
                Conteúdo nutricional cientificamente balanceado e adequado à
                baixa ingestão calórica.
              </p>
            </div>
            <div class="more-information-item">
              <p class="information-title">Calorias Reduzidas</p>
              <p class="information-description">
                Conteúdo nutricional cientificamente balanceado e adequado à
                baixa ingestão calórica.
              </p>
            </div>
            <div class="more-information-item">
              <p class="information-title">Calorias Reduzidas</p>
              <p class="information-description">
                Conteúdo nutricional cientificamente balanceado e adequado à
                baixa ingestão calórica.
              </p>
            </div>
          </div>
        </div>

        <div class="garantia" *ngIf="tabIndex == 1">
          <div *ngFor="let garantia of garantias; let last = last">
            <h5 class="tab-title">{{ garantia.categoria | uppercase }}</h5>
            <div class="gap-3 content">
              <div *ngFor="let item of garantia.itens">
                <h6 class="subtitle">{{ item.nome }}</h6>
                <span class="subtitle-value">{{
                  item.valor | number: '1.2-2'
                }}</span>
                <span class="subtitle-unidade">{{ item.unidade }}</span>
              </div>
            </div>
            <hr class="line" *ngIf="!last" />
          </div>
        </div>

        <div class="composicao" *ngIf="tabIndex == 2">
          Farinha de vísceras de frango, farinha de torresmo, glúten de trigo,
          plasma sanguíneo desidratado de suíno, ovo em pó, ervilha moída
          desidratada, cevada em grão, quirera de arroz, celulose, fibra de
          cana-de-açúcar, polpa desidratada de beterraba, gordura de frango,
          óleo refinado de peixe, cloreto de potássio, cloreto de sódio,
          levedura de cana-de-açúcar autolisada e desidratada, acácia nilótica,
          ácido propiônico, BHA (Butilhidroxianisol), BHT (Butilhidroxitolueno),
          cúrcuma (Curcuma longa), extrato de Yucca, fosfatidilcolina,
          frutooligossacarídeos, gelatina hidrolisada (2,5%), hidrolisado de
          fígado de aves e suíno, líquido da casca da castanha de caju (LCC),
          L-carnitina, óleo de copaíba, oleoresina de pimenta, parede celular de
          levedura (fonte de beta glucanas), taurina, acetato de
          DL-alfa-tocoferol, acetato de retinol, ácido ascórbico monofosfato,
          ácido fólico, ácido pantotênico, biotina, bissulfito de menadiona
          nicotinamida, cianocobalamina, cloreto de colina, colecalciferol,
          niacina (ácido nicotínico), piridoxina, riboflavina, tiamina, ferro
          aminoácido quelato, iodeto de cálcio, manganês aminoácido quelato,
          selenometionina hidroxi análoga, sulfato de cobre pentahidratado,
          sulfato de ferro, sulfato de manganês, sulfato de zinco monohidratado,
          zinco aminoácido quelato.
        </div>

        <div class="enriquecimento" *ngIf="tabIndex == 3">
          <div *ngFor="let enriquecimento of enriquecimentos; let last = last">
            <h5 class="tab-title">
              {{ enriquecimento.categoria | uppercase }}
            </h5>
            <div class="gap-3 content">
              <div *ngFor="let item of enriquecimento.itens">
                <h6 class="subtitle">{{ item.nome }}</h6>
                <span class="subtitle-value">{{
                  item.valor | number: '1.2-2'
                }}</span>
                <span class="subtitle-unidade">{{ item.unidade }}</span>
              </div>
            </div>
            <hr class="line" *ngIf="!last" />
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
