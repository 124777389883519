<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ tutor ? 'Editar' : 'Cadastrar' }} Tutor</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Fechar"
      (click)="closeModal()"
    >
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>
  <div class="modal-body">
    <form
      #formDiv
      [formGroup]="tutorForm"
      class="modal-form tutor-modal"
      [style.padding-bottom]="!addressCollapsed ? '24px' : ''"
    >
      <field-validate
        fieldName="Nome"
        [showSuccessBackground]="false"
        [customErrorMessage]="'Preenchimento obrigatório'"
      >
        <input
          maxlength="80"
          onlyText
          type="text"
          formControlName="nome"
          placeholder="Informe o nome do tutor"
          (change)="changedFields = true"
        />
      </field-validate>

      <div class="field-toggle">
        <field-validate
          class="validate"
          fieldName="Enviar a orientação para o WhatsApp do tutor?"
          [showSuccessBackground]="false"
          [customErrorMessage]="'Informe um WhatsApp válido'"
          [showField]="
            tutor?.telefoneMovel || tutor?.telefoneFixo
              ? !whatsappField
              : whatsappField
          "
        >
          <input
            [required]="
              tutor?.telefoneMovel || tutor?.telefoneFixo
                ? !whatsappField
                : whatsappField
            "
            type="text"
            mask="(00) 00000-0000||(00) 0000-0000"
            formControlName="telefoneMovel"
            placeholder="(00) 00000-0000"
            (change)="changedFields = true"
          />
        </field-validate>

        <ng-toggle
          class="toggle"
          [value]="
            tutor?.telefoneMovel || tutor?.telefoneFixo
              ? !whatsappField
              : whatsappField
          "
          [color]="{
            unchecked: '#becad2',
            checked: '#5eacff'
          }"
          [width]="50"
          [height]="24"
          (change)="onToggleChangeWhatsapp()"
        ></ng-toggle>
      </div>

      <div class="field-toggle">
        <field-validate
          class="validate"
          fieldName="Enviar a orientação automaticamente para o e-mail do tutor?"
          [showSuccessBackground]="false"
          [customErrorMessage]="'Informe um e-mail válido'"
          [showField]="tutor?.email ? !emailField : emailField"
        >
          <input
            [required]="tutor?.email ? !emailField : emailField"
            maxlength="100"
            type="text"
            formControlName="email"
            placeholder="email@premiervet.com.br"
            (change)="changedFields = true"
          />
        </field-validate>

        <ng-toggle
          class="toggle"
          [value]="tutor?.email ? !emailField : emailField"
          [color]="{
            unchecked: '#becad2',
            checked: '#5eacff'
          }"
          [width]="50"
          [height]="24"
          (change)="onToggleChangeEmail()"
        ></ng-toggle>
      </div>
    </form>
  </div>

  <div class="modal-footer" [class.show-shadow]="showFooterShadow()">
    <button class="button button-link" (click)="closeModal()">Cancelar</button>
    <button class="button button-primary" (click)="onSubmit()">
      <span>Salvar</span>
      <i class="bi bi-check-circle"></i>
    </button>
  </div>
</div>
