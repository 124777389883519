import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  getLoadingHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('loading', 'true');
    return headers;
  }
}
