import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  async convertAndDownloadPdf(data: any, fileName?: string): Promise<boolean> {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
    return true;
  }
}
