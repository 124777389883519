import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[custom-regex]'
})
export class CustomRegexDirective {
  @Input('custom-regex') regexPattern!: string;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (this.regexPattern) {
      const regex = new RegExp(this.regexPattern);
      const isValid = regex.test(e.key);
      if (!isValid) {
        e.preventDefault();
      }
    }
  }
}
