import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-avatar-user',
  templateUrl: './avatar-user.component.html',
  styleUrls: ['./avatar-user.component.scss']
})
export class AvatarUserComponent implements OnInit {
  @Input() profilePhoto: string = '';
  @Output() profilePhotoEmitter: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.getProfilePhoto();
  }

  getProfilePhoto(): void {
    this.authService.loadUserById().subscribe((response) => {
      this.profilePhoto = response.pictureUrl;
      this.profilePhotoEmitter.emit(this.profilePhoto);
    });
  }
}
