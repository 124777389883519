import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../../services/notification/notification.service';

@Component({
  selector: 'app-generic-done',
  templateUrl: './generic-done.component.html',
  styleUrls: ['./generic-done.component.scss']
})
export class GenericDoneComponent implements OnInit {
  @Input() message: string;
  showOverlay: boolean = true;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.showOverlay = false;
      if (this.message) {
        this.notificationService.success(this.message);
      }
    }, 2300);
  }
}
