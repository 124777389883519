import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-generic-tags',
  templateUrl: './generic-tags.component.html',
  styleUrls: ['./generic-tags.component.scss']
})
export class GenericTagsComponent implements OnChanges {
  @Output() valorTags: EventEmitter<any> = new EventEmitter();
  @Input() titulo: string;
  @Input() subtitulo: string;
  @Input() edicao: boolean;
  @Output() tagsSelecionadas = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() tagCastrado = new EventEmitter();
  @Input() disabled = false;
  @Input() condicoesSelecionadas: any;
  @Input('condicoes')
  set setCondicoes(condicoes: any) {
    this.conditions = condicoes;
    this.checkForSelectedValues();
  }

  conditions: any[];
  selectedTags: any[] = [];
  model: any = null;
  castrated = false;
  pet: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condicoesSelecionadas) {
      if (this.condicoesSelecionadas) {
        this.selectedTags = this.condicoesSelecionadas || [];
      }
    }
  }

  checkForSelectedValues() {
    if (this.pet && this.pet.id && this.conditions.length) {
      this.selectedTags = [];
      this.conditions = [...this.conditions];
      if (this.titulo == 'Condições Clinicas') {
        this.pet.petCondicoesClinicas.forEach((res: any) => {
          this.validateClinicalCharacteristicByGender(res);
        });
      } else if (this.titulo == 'Comportamentos') {
        this.pet.comportamentos.forEach((res: any) => {
          this.selectedTags.push(this.conditions.find((x: any) => x.id == res));
          this.tagsSelecionadas.emit(this.selectedTags);
        });
      }
    }
  }

  validateClinicalCharacteristicByGender(clinicalCharacteristicId: number) {
    const tag = this.conditions.find(
      (x: any) => x.id == clinicalCharacteristicId
    );

    if (tag !== undefined) {
      this.selectedTags.push(tag);
      this.tagsSelecionadas.emit(this.selectedTags);

      const conditionIndex = this.conditions.findIndex(
        (x: any) => x.id == clinicalCharacteristicId
      );
      this.conditions.splice(conditionIndex, 1);
    }
  }

  removeTag(item: any) {
    const indexSelectedTags = this.selectedTags.findIndex(
      (x) => item.text == x.text
    );

    if (indexSelectedTags > -1) {
      this.selectedTags.splice(indexSelectedTags, 1);
    }

    this.conditions.push(item);
    this.model = 'null';

    this.conditions = this.conditions.sort((a: any, b: any) => {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();

      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }

      return 0;
    });

    this.change.emit(indexSelectedTags);
    this.tagsSelecionadas.emit(this.selectedTags);
  }

  addTag() {
    if (!this.model || this.model == 'null') {
      return;
    }

    const repetValue = this.selectedTags.find((x) => this.model.id == x.id);

    if (!repetValue) {
      this.selectedTags.push(this.model);
      const getCastrated = this.selectedTags.find((x) => x.id == 2);
      if (getCastrated) {
        this.castrated = true;
      }
    }

    this.tagsSelecionadas.emit(this.selectedTags);
    this.tagCastrado.emit(this.castrated);
    this.conditions = [...this.conditions];
    const indexSelected = this.conditions.findIndex(
      (x: any) => this.model.id == x.id
    );

    if (indexSelected > -1) {
      this.conditions.splice(indexSelected, 1);
    }
  }
}
