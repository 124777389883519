<div class="offcanvas px-3 py-4">
  <div class="modal-header">
    <h4 class="modal-title">Enviar orientação por e-mail</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Fechar"
      (click)="closeModal()"
    >
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>
  <div class="offcanvas-body">
    <div [formGroup]="emailForm" class="content">
      <img
        src="../../../../../assets/images/registration/email-send.svg"
        alt=""
      />
      <p class="subtitle">
        Informe para quais e-mails deseja enviar a orientação.
      </p>

      <div
        (click)="focusInput()"
        [class.error]="showErrorMessage || showMaxEmailsError"
        class="content-emails"
      >
        <div class="email-tag" *ngFor="let email of emails">
          <span>{{ email }}</span>
          <button class="remove-email" (click)="removeEmail(email)">
            <i class="bi bi-x"></i>
          </button>
        </div>
        <div></div>
        <input
          #inputEmail
          [class.error]="showErrorMessage || showMaxEmailsError"
          formControlName="email"
          placeholder="Digite os e-mails separados por vírgula"
          type="text"
          class="input-tag"
          (keyup)="onKeyUp($event)"
        />
      </div>
      <span class="span-error" *ngIf="showErrorMessage || showMaxEmailsError">
        {{
          showMaxEmailsError
            ? 'Limite máximo de ' + maxEmails + ' destinatários'
            : 'Digite um e-mail válido'
        }}
      </span>
    </div>

    <div class="modal-footer">
      <button class="button button-link" (click)="closeModal()">
        Cancelar
      </button>
      <button class="button button-primary" (click)="onSubmit()">
        <span>Enviar e-mail</span>
        <img src="../../../assets/icons/mail-white.svg" alt="" />
      </button>
    </div>
  </div>
</div>
