import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { RequestEvent } from 'src/app/modules/shared/components/infinite-select/infinite-select.component';
import {
  University,
  UniversityWithName
} from 'src/app/services/static/models/universities';
import { StaticService } from 'src/app/services/static/static.service';

@Component({
  selector: 'university-select',
  templateUrl: './university-select.component.html',
  styleUrls: ['./university-select.component.scss']
})
export class UniversitySelectComponent implements OnInit {
  universities: UniversityWithName[] = [];
  universitySelected: UniversityWithName | null = null;
  filterUniversitiesSubject = new Subject<RequestEvent>();
  registeredUniversity: string;
  universityModalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  isUniversityEdit = false;
  phoneWidth = 576;
  exactSearch = false;
  autoComplete: boolean = false;
  loading = false;

  @Output() UniversitySelectedChange = new Subject<UniversityWithName>();

  constructor(
    private statisService: StaticService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadUniversities();

    this.filterUniversitiesSubject
      .pipe(
        debounceTime(250),
        tap(() => (this.loading = true)),
        switchMap((action) => {
          if (this.universitySelected) return [];

          return this.statisService
            .getAllUniversities(action.value, 1, action.itemsPerPage)
            .pipe(finalize(() => (this.loading = false)));
        })
      )
      .subscribe((res) => {
        const universitiesWithNameConcat = res.items.map(
          this.concatUniversityName
        );
        this.universities = universitiesWithNameConcat;
      });
  }

  concatUniversityName(university: University): UniversityWithName {
    const nameContent: string[] = [];
    if (university.uf) nameContent.push(university.uf);
    if (university.acronym) nameContent.push(university.acronym);
    if (university.name) nameContent.push(university.name);

    const nameFull = nameContent.join(' - ');

    return {
      ...university,
      nameFull
    };
  }

  loadUniversities(): void {
    this.loading = true;
    this.statisService
      .getAllUniversities('', 1, 10)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((res) => {
        const universitiesWithNameConcat = res.items.map(
          this.concatUniversityName
        );
        this.universities = universitiesWithNameConcat;
        console.log('Universidades carregadas:', this.universities);
      });
  }

  onUniversitySelected(university: UniversityWithName): void {
    this.universitySelected = university;
    this.UniversitySelectedChange.next(university);
  }

  subscribeSavedUniversity(): void {
    this.universityModalRef.content.savedUniversity.subscribe(
      (savedUniversity: UniversityWithName): void => {
        if (savedUniversity) {
          this.exactSearch = true;
          this.onUniversitySelected(savedUniversity);
          this.changeDetectorRef.detectChanges();
        }
      }
    );
  }
}
