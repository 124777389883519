import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ListFilterButtonModel } from './list-filter-button-model';

@Component({
  selector: 'app-list-filter-button',
  templateUrl: './list-filter-button.component.html',
  styleUrls: ['./list-filter-button.component.scss']
})
export class ListFilterButtonComponent {
  @Input() hasIcon: boolean = true;
  @Input() descriptionButton: string;
  @Input() listByFilter: ListFilterButtonModel[];
  @ViewChild('listRef') listRef!: ElementRef;
  isOpenFilterList: boolean = false;
  @Output() filterSelected = new EventEmitter<ListFilterButtonModel[]>();
  countFiltersActive: number = 0;

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.listRef.nativeElement.contains(event.target)) {
      this.isOpenFilterList = false;
    }
  }

  constructor() {}

  removeFilters(): void {
    this.listByFilter.forEach((list) => (list.checked = false));
    this.countFiltersActive = 0;
    this.filterSelected.emit([]);
  }

  onCheckboxChange(): void {
    const itemsChecked = this.listByFilter.filter((x) => x.checked);
    this.countFiltersActive = itemsChecked.length;
    this.filterSelected.emit(itemsChecked);
  }
}
