<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">Dica!</h4>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>

    <div class="modal-actions">
      <button class="button button-close" (click)="close()">Fechar</button>
    </div>
  </div>
</div>
