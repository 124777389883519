export const environment = {
  production: false,
  apiVersion: '0.1.0',
  subscriptionKey: 'b5d1e35922a145b0acc7b4742dddbab9',
  trace: 'false',
  pixelMeta: '712880744130635',
  baseApimUrl: 'https://apim-premierpet-development.azure-api.net/premiervet',
  baseBlobUrl:
    'https://safuncpremiervetappdev.blob.core.windows.net/prescricao',
  apiCalculator: 'https://apim-premierpet-development.azure-api.net/calculator',
  // apis-end
  urlPartnerProgram:
    'https://www.premierpet.com.br/veterinarios/parceiro-premiervet/',
  urlPvet: 'https://dev.premiervet.com.br/',
  urlLp: 'https://app-landingpage-prd-dev.azurewebsites.net/ ',
  limiteArquivoClinica: 1000000,
  hotjarConfig: {
    siteId: 0,
    version: 6
  },
  domain: '.premiervet.com.br',
  urlBlog: 'https://devlp.premiervet.com.br/blogvet',
  lumisCmsBlogUrl: 'https://premierpetpoc.lumis.dev/blogs/',
  urlTermsPdf:
    'https://sapremiervetprd01.blob.core.windows.net/public-files/PremierVet_Termo_e_Poli%CC%81ticas.pdf',
  insights: {
    instrumentationKey: 'aed2dfc9-3c0c-4c10-8d91-c9d770571f39'
  },
  redirectUniversity:
    'https://www.premierpet.com.br/universitarios/premios-de-pesquisa/9o-premio/',
  urlwhereBuyPremier: 'https://www.premierpet.com.br/onde-comprar/',
  urlPremierPetDogs: 'https://www.premierpet.com.br/caes/',
  urlPremierPetCats: 'https://www.premierpet.com.br/gatos/',
  urlInstagram: 'https://www.instagram.com/premierpet/',
  urlYoutube: 'https://www.youtube.com/@PremieRpetOficial',
  firebase: {
    apiKey: 'AIzaSyDKp_wM0GGXlKSJc9AdtKCzDMkOJE84uPo',
    authDomain: 'premiervet-a642b.firebaseapp.com',
    projectId: 'premiervet-a642b',
    storageBucket: 'premiervet-a642b.appspot.com',
    messagingSenderId: '583439329050',
    appId: '1:583439329050:web:a8ffe1829337724b39eea3',
    measurementId: 'G-0LQBW9PP8N',
    vapidKey:
      'BBJyMRkaGrJyr-ws-raioRKJCp8I_QFPL9T4KXSGQyF0gUOPTbz7fqibj0lzLSlg8DMPCSR9irMMGS2_h_U5K-E'
  }
};
