import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  pathImg = '../../../../../assets/images/logo-premiervet-r.png';
  @Input() urlNavigation: string;
  @Input() hideMobileBackButton = false;
  @Output() backEmitter = new EventEmitter<void>();
  mobileScreen: boolean;
  screenWidth: number;
  isLoginScreen = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isLoginScreen = this.router.url === '/login';
    this.getScreenSize();
  }

  getNavigate(): void {
    window.location.href = this.urlNavigation;
    this.backEmitter.emit();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576
      ? (this.mobileScreen = false)
      : (this.mobileScreen = true);
  }
}
