import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Periods } from 'src/app/services/static/models/periods';
import { States } from 'src/app/services/static/models/states';
import { University } from 'src/app/services/static/models/universities';
import { environment } from 'src/environments/environment';
import { AppListResponse } from '../shared/app.response';

@Injectable({
  providedIn: 'root'
})
export class StaticService {
  constructor(private http: HttpClient) {}

  private readonly urlStatic = `${environment.baseApimUrl}/static`;

  getAllAges(): Observable<number[]> {
    const url = this.urlStatic + '/petages';
    return this.http.get<number[]>(url);
  }

  getAllMonths(): Observable<Periods[]> {
    const url = this.urlStatic + '/monthsOfYear';
    return this.http.get<Periods[]>(url);
  }

  getAllUniversities(
    searchExpression: string,
    pageNumber: number = 1,
    pageSize: number = 10
  ): Observable<AppListResponse<University>> {
    const url = `${this.urlStatic}/universities/search`;
    const params = {
      searchExpression,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString()
    };

    return this.http.get<AppListResponse<University>>(url, {
      params
    });
  }

  getAllPeriods(): Observable<Periods[]> {
    const url = this.urlStatic + '/periods';
    return this.http.get<Periods[]>(url);
  }

  getAllStatesOfBrazil(): Observable<States[]> {
    const url = this.urlStatic + '/allStatesOfBrazil';
    return this.http.get<States[]>(url);
  }
}
