import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  ViewChild,
  Output,
  Input
} from '@angular/core';

@Component({
  selector: 'app-mobile-modal-overlay',
  templateUrl: './mobile-modal-overlay.component.html',
  styleUrls: ['./mobile-modal-overlay.component.scss']
})
export class MobileModalOverlayComponent implements OnInit {
  constructor() {}
  @ViewChild('modalContent') modalContent!: ElementRef;
  modalOpened = false;
  @Input() showButton = true;

  @Output() closeModalEmitter = new EventEmitter<boolean>();
  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (
      !this.modalContent.nativeElement.contains(event.target) &&
      this.modalOpened
    ) {
      this.closeModal();
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalOpened = true;
    }, 500);
  }

  closeModal(): void {
    this.closeModalEmitter.next(true);
  }
}
