import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { States } from '../services/static/models/states';
import { StaticService } from '../services/static/static.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { NotificationService } from '../services/notification/notification.service';
import { finalize, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-registration-update-modal',
  templateUrl: './registration-update-modal.component.html',
  styleUrl: './registration-update-modal.component.scss'
})
export class RegistrationUpdateModalComponent implements OnInit, OnChanges {
  @Input() isDesktop = false;
  @Input() isVisible = false;
  @Input() onClose!: () => void;

  @ViewChild('desktop') desktopTemplate!: string;
  @ViewChild('mobile') mobileTemplate!: string;

  modalRef: BsModalRef = null;
  crmvForm: UntypedFormGroup;
  listStates: States[] = [];
  isLoading = false;

  constructor(
    private bsModalService: BsModalService,
    private staticService: StaticService,
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getListStatesOfBrazil();
    this.initializeForm();
  }

  getListStatesOfBrazil(): void {
    this.staticService.getAllStatesOfBrazil().subscribe((res) => {
      this.listStates = res;
    });
  }

  initializeForm(): void {
    this.crmvForm = this.fb.group({
      numeroCrmv: [null, [Validators.required, Validators.minLength(4)]],
      estadoCrmv: [null, Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['isVisible'] || !changes['isVisible'].currentValue) return;

    this.modalRef = this.bsModalService.show(
      this.isDesktop ? this.desktopTemplate : this.mobileTemplate,
      {
        class: this.isDesktop
          ? 'desktop-modal modal-sm'
          : 'mobile-modal modal-xl'
      }
    );
  }

  closeModal() {
    this.onClose();
    this.modalRef.hide();
    this.modalRef = null;
  }

  onSubmit() {
    if (this.crmvForm.valid) {
      this.isLoading = true;
      this.authenticationService
        .getUser()
        .pipe(
          take(1),
          switchMap((user) => {
            user.numeroCrmv = this.crmvForm.get('numeroCrmv').value as string;
            user.estadoCrmv = this.crmvForm.get('estadoCrmv').value as string;

            return this.authenticationService.updateStudent(user);
          }),
          finalize(() => (this.isLoading = false))
        )
        .subscribe({
          next: (response) => {
            this.authenticationService.setUser(response.item);
            this.notificationService.success(
              'Perfil atualizado para médico-veterinário!'
            );
            this.closeModal();
          },
          error: (err) => {
            console.error('Erro ao atualizar o usuário:', err);
          }
        });
    } else {
      this.crmvForm.markAllAsTouched();
    }
  }
}
