<main class="modal-container offcanvas">
  <div class="modal-header row">
    <button type="button" (click)="close()" class="col-auto btn-return">
      <i class="bi bi-arrow-left btn-return__icon"></i>
      <span class="btn-return__title">Voltar</span>
    </button>
    <h5 class="col-8 modal-title">
      Alterar {{ titleFilter[typeProduct] }}

      <span class="division">
        <i class="bi bi-dash-lg"></i>
        <span class="label-filters"> Filtros </span>
      </span>
    </h5>

    <button
      type="button"
      class="col-auto close pull-right"
      (click)="close()"
      aria-label="Fechar"
    >
      <i class="bi bi-x icon__close"></i>
    </button>
  </div>

  <div class="offcanvas-body">
    <form [formGroup]="filtersForm">
      <section *ngIf="lines.length">
        <div
          class="list-options"
          id="id-filters-change-product-first-list-options"
          (click)="isCollapsedLines = !isCollapsedLines"
        >
          <label> Linha </label>
          <i
            [ngClass]="
              isCollapsedLines ? 'bi bi-chevron-down' : 'bi bi-chevron-up'
            "
          ></i>
        </div>

        <div
          class="list-container"
          id="collapseEvent"
          [isAnimated]="false"
          [collapse]="isCollapsedLines"
        >
          <div class="form-check" *ngFor="let dataLine of lines">
            <label class="label-checkbox" [for]="dataLine.name">
              {{ dataLine.name }}
            </label>
            <input
              (change)="onCheckboxChange('lines', $event)"
              class="input-checkbox outline-primary"
              type="checkbox"
              [value]="dataLine.id"
              [id]="dataLine.name"
              [checked]="isCheckboxChecked('lines', dataLine.id)"
            />
          </div>
        </div>
      </section>

      <section *ngIf="ages.length">
        <div class="list-options" (click)="isCollapsedAges = !isCollapsedAges">
          <label> Idade</label>
          <i
            [ngClass]="
              isCollapsedAges ? 'bi bi-chevron-down' : 'bi bi-chevron-up'
            "
          ></i>
        </div>

        <div
          class="list-container"
          id="collapseEvent"
          [isAnimated]="false"
          [collapse]="isCollapsedAges"
        >
          <div class="form-check" *ngFor="let dataAge of ages">
            <label class="label-checkbox" [for]="dataAge.name">
              {{ dataAge.name }}
            </label>
            <input
              (change)="onCheckboxChange('ages', $event)"
              class="input-checkbox outline-primary"
              type="checkbox"
              [value]="dataAge.id"
              [id]="dataAge.name"
              [checked]="isCheckboxChecked('ages', dataAge.id)"
            />
          </div>
        </div>
      </section>

      <section *ngIf="agesByAge.length">
        <div class="list-options" (click)="isCollapsedAges = !isCollapsedAges">
          <label> Faixa Etária</label>
          <i
            [ngClass]="
              isCollapsedAges ? 'bi bi-chevron-down' : 'bi bi-chevron-up'
            "
          ></i>
        </div>

        <div
          class="list-container"
          id="collapseEvent"
          [isAnimated]="false"
          [collapse]="isCollapsedAges"
        >
          <div class="form-check" *ngFor="let dataAgeByAge of agesByAge">
            <label class="label-checkbox" [for]="dataAgeByAge.name">
              {{ dataAgeByAge.name }}
            </label>
            <input
              (change)="onCheckboxChange('agesByAge', $event)"
              class="input-checkbox outline-primary"
              type="checkbox"
              [value]="dataAgeByAge.name"
              [id]="dataAgeByAge.name"
              [checked]="isCheckboxChecked('agesByAge', dataAgeByAge.name)"
            />
          </div>
        </div>
      </section>

      <section *ngIf="sizes.length">
        <div class="list-options" (click)="isCollapseSizes = !isCollapseSizes">
          <label> Porte</label>
          <i
            [ngClass]="
              isCollapseSizes ? 'bi bi-chevron-down' : 'bi bi-chevron-up'
            "
          ></i>
        </div>

        <div
          class="list-container"
          id="collapseEvent"
          [isAnimated]="false"
          [collapse]="isCollapseSizes"
        >
          <div class="form-check" *ngFor="let dataSize of sizes">
            <label class="label-checkbox" [for]="dataSize.name">
              {{ dataSize.name }}
            </label>
            <input
              (change)="onCheckboxChange('sizes', $event)"
              class="input-checkbox outline-primary"
              type="checkbox"
              [value]="dataSize.id"
              [id]="dataSize.name"
              [checked]="isCheckboxChecked('sizes', dataSize.id)"
            />
          </div>
        </div>
      </section>

      <section *ngIf="flavors.length">
        <div
          class="list-options"
          (click)="isCollapseFlavors = !isCollapseFlavors"
        >
          <label for=""> Sabor</label>
          <i
            [ngClass]="
              isCollapseFlavors ? 'bi bi-chevron-down' : 'bi bi-chevron-up'
            "
          ></i>
        </div>

        <div
          class="list-container mb-4"
          id="collapseEvent"
          [isAnimated]="false"
          [collapse]="isCollapseFlavors"
        >
          <div class="form-check" *ngFor="let flavors of flavors">
            <label class="label-checkbox" [for]="flavors">
              {{ flavors }}
            </label>
            <input
              (change)="onCheckboxChange('flavors', $event)"
              class="input-checkbox outline-primary"
              type="checkbox"
              [value]="flavors"
              [id]="flavors"
              [checked]="isCheckboxChecked('flavors', flavors)"
            />
          </div>
        </div>
      </section>
    </form>
  </div>
  <section class="button-container">
    <button type="button" class="button__remove-filter" (click)="resetFilter()">
      <i class="bi bi-trash"></i> Remover filtros
    </button>
    <button class="button__show-products" (click)="submit()">
      Aplicar filtros
    </button>
  </section>
</main>
