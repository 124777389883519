<div class="container">
  <div class="top-box"></div>
  <div class="flex-row box-geral">
    <h1>Em breve!</h1>
    <p>
      Por enquanto a calculadora nutricional só está disponível para desktop. Em
      breve você vai poder acessá-la por aqui :)
    </p>
  </div>
</div>
