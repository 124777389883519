import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface Cep {
  cep: string;
  logradouro: string;
  complemento: string;
  localidade: string;
  bairro: string;
  uf: string;
  ddd: string;
}

@Injectable({
  providedIn: 'root'
})
export class ViacepService {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  getCep(cep: string): Observable<Cep> {
    return this.http.get<Cep>(`https://viacep.com.br/ws/${cep}/json/`);
  }
}
