import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  AfterContentChecked
} from '@angular/core';
import { RadioButtonModel } from './radio-button-model';

@Component({
  selector: 'app-radio-button-generic',
  templateUrl: './radio-button-generic.component.html',
  styleUrls: ['./radio-button-generic.component.scss']
})
export class RadioButtonGenericComponent implements AfterContentChecked {
  @Input() reset = false;
  @Input() options: RadioButtonModel[] = [];
  @Input() mobileColumns = 2;
  selected: RadioButtonModel;

  @Output() optionSelected = new EventEmitter<RadioButtonModel>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  sendEvent(option: RadioButtonModel): void {
    this.selected = option;
    this.optionSelected.emit(option);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    if (this.reset) {
      this.selected = new RadioButtonModel();
      this.sendEvent(this.selected);
    }
  }
}
