import { Component, Input, OnInit } from '@angular/core';
import { GradualExchange } from 'src/app/services/calculator/models/calculator-product-result-model';

@Component({
  selector: 'app-gradual-change-mobile',
  templateUrl: './gradual-change-mobile.component.html',
  styleUrls: ['./gradual-change-mobile.component.scss']
})
export class GradualChangeMobileComponent implements OnInit {
  @Input() gradualChange: boolean = false;
  @Input() listProductsOfGradualChange: GradualExchange[];

  urlImgGradualChange: string =
    '../../../../../assets/images/gradual-exchange/';
  lengthList: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.lengthList = this.listProductsOfGradualChange.length;
  }
}
