import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRangeService } from 'src/app/services/calendar/date-range.service';
import { StaticService } from 'src/app/services/static/static.service';

const SEVEN_DAYS = 6;
const THIRTY_DAYS = 29;

@Component({
  selector: 'app-calendar-desktop, p-calendar-desktop',
  templateUrl: './calendar-desktop.component.html',
  styleUrls: ['./calendar-desktop.component.scss']
})
export class CalendarDesktopComponent implements OnInit {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() allPeriod: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedRangeChanged: EventEmitter<{
    startDate: Date;
    endDate: Date;
  }> = new EventEmitter<{ startDate: Date; endDate: Date }>();

  nextMonthIsAfterToday = false;
  isSeptember2022 = false;

  currentMonth: string;
  previousMonth: string;
  currentDate: Date;
  selectedDates: Date[] = [];

  selectedRangeStartDate: Date | null = null;
  selectedRangeEndDate: Date | null = null;
  selectingStartDate = true;

  constructor(
    private staticService: StaticService,
    private dateRangeService: DateRangeService
  ) {}

  ngOnInit(): void {
    this.selectedRangeStartDate = this.dateRangeService.selectedRangeStartDate;
    this.selectedRangeEndDate = this.dateRangeService.selectedRangeEndDate;

    this.getMonthName();
  }

  previousStep(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);

    this.updateMonths();
  }

  nextStep(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);

    this.updateMonths();
  }

  isDayDifference(days: number): boolean {
    if (this.selectedRangeStartDate && this.selectedRangeEndDate) {
      const differenceInDays = Math.ceil(
        Math.abs(
          (this.selectedRangeEndDate.getTime() -
            this.selectedRangeStartDate.getTime()) /
            (1000 * 3600 * 24)
        )
      );

      return differenceInDays === days;
    }

    return false;
  }

  isBetweenLastYear(): boolean {
    if (this.selectedRangeStartDate && this.selectedRangeEndDate) {
      const differenceInDays = Math.ceil(
        Math.abs(
          (this.selectedRangeEndDate.getTime() -
            this.selectedRangeStartDate.getTime()) /
            (1000 * 3600 * 24)
        )
      );

      return differenceInDays === 365 || differenceInDays === 366;
    }

    return false;
  }

  applySelectedRange(): void {
    if (this.selectedRangeStartDate && this.selectedRangeEndDate) {
      this.dateRangeService.setRange(
        this.selectedRangeStartDate,
        this.selectedRangeEndDate
      );

      this.selectedRangeChanged.emit({
        startDate: this.selectedRangeStartDate,
        endDate: this.selectedRangeEndDate
      });
    }

    this.onCloseModal();
  }

  getAllPeriod() {
    this.allPeriod.emit();
    this.dateRangeService.clearRange();
    this.onCloseModal();
  }

  getLast7Days() {
    this.setDateRangeWithDaysAgo(SEVEN_DAYS);
  }

  getLast30Days() {
    this.setDateRangeWithDaysAgo(THIRTY_DAYS);
  }

  getLastYear() {
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    const lastYearStartDate = new Date(currentDate);
    lastYearStartDate.setFullYear(currentDate.getFullYear() - 1, 0, 1);

    const lastYearEndDate = new Date(currentDate);

    lastYearEndDate.setFullYear(currentDate.getFullYear() - 1, 11, 31);
    lastYearEndDate.setHours(23, 59, 59);

    this.selectedRangeStartDate = lastYearStartDate;
    this.selectedRangeEndDate = lastYearEndDate;

    this.applySelectedRange();
    this.onCloseModal();
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    const formattedDate = date.toLocaleDateString('pt-BR', options);
    return formattedDate;
  }

  isDateSelected(date: Date): boolean {
    return this.selectedDates.some(
      (selectedDate) =>
        selectedDate.getDate() === date.getDate() &&
        selectedDate.getMonth() === date.getMonth() &&
        selectedDate.getFullYear() === date.getFullYear()
    );
  }

  onSelectDate(date: Date): void {
    if (this.selectingStartDate) {
      this.selectedRangeStartDate = date;
      this.selectedRangeEndDate = date;
    } else {
      if (this.selectedRangeStartDate && date >= this.selectedRangeStartDate) {
        this.selectedRangeEndDate = date;
      }
    }

    this.selectingStartDate = !this.selectingStartDate;
  }

  onCloseModal() {
    this.closeModal.emit();
  }

  private getMonthName(): void {
    const today = new Date();
    const previousMonthDate = new Date(today);

    this.staticService.getAllMonths().subscribe((response) => {
      const monthName = response.map((month) => month.month);

      this.currentMonth =
        monthName[today.getMonth()] + ' ' + today.getFullYear();

      previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

      if (today.getMonth() === 0) {
        previousMonthDate.setMonth(11);
        previousMonthDate.setFullYear(previousMonthDate.getFullYear() - 1);
      }

      this.previousMonth =
        monthName[previousMonthDate.getMonth()] +
        ' ' +
        previousMonthDate.getFullYear();

      this.currentDate = new Date();
    });
  }

  private setDateRangeWithDaysAgo(daysAgo: number) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - daysAgo);

    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59);

    this.selectedRangeStartDate = startDate;
    this.selectedRangeEndDate = endDate;

    this.applySelectedRange();
  }

  updateMonths(): void {
    const today = new Date();
    const currentMonthDate = new Date(this.currentDate);
    const september2022 = new Date(2022, 9);

    this.staticService.getAllMonths().subscribe((response) => {
      const monthName = response.map((month) => month.month);

      this.nextMonthIsAfterToday =
        currentMonthDate.getFullYear() > today.getFullYear() ||
        (currentMonthDate.getFullYear() === today.getFullYear() &&
          currentMonthDate.getMonth() > today.getMonth());

      this.currentMonth =
        monthName[currentMonthDate.getMonth()] +
        ' ' +
        currentMonthDate.getFullYear();

      const previousMonthDate = new Date(currentMonthDate);
      previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

      if (previousMonthDate.getMonth() === -1) {
        previousMonthDate.setMonth(11);
        previousMonthDate.setFullYear(previousMonthDate.getFullYear() - 1);
      }

      this.previousMonth =
        monthName[previousMonthDate.getMonth()] +
        ' ' +
        previousMonthDate.getFullYear();

      this.isSeptember2022 = previousMonthDate < september2022;
    });
  }
}
