<div class="gradual-change-container override-padding" *ngIf="gradualChange">
  <div
    *ngFor="let item of listProductsOfGradualChange"
    class="gradual-change"
    [ngStyle]="{
      'margin-right':
        item.id ===
        listProductsOfGradualChange[listProductsOfGradualChange.length - 1].id
          ? '16px'
          : ''
    }"
  >
    <span class="generic-description" [innerHTML]="item.day"></span>
    <img
      class="img-gradual-exchange"
      [src]="'../../../assets/images/gradual-exchange/' + item.imageName"
      alt=""
    />
    <p class="gradual-change-quantity" [innerHTML]="item.description"></p>
  </div>
</div>
