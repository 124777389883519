import { AbstractControl } from '@angular/forms';
import { CustomValidators } from './custom-validators';

export function ValidateHasNumber(control: AbstractControl) {
  const regexNumber: RegExp = new RegExp(/\d/);

  if (!regexNumber.test(control.value) && control.value) {
    return { hasNumber: true };
  }
  return null;
}

export function ValidateHasCapitalCase(control: AbstractControl) {
  const regexCapitalCase: RegExp = new RegExp(/[A-Z]/);

  if (!regexCapitalCase.test(control.value) && control.value) {
    return { hasCapitalCase: true };
  }
  return null;
}

export function ValidatehasSmallCase(control: AbstractControl) {
  const regexSmallCase: RegExp = new RegExp(/[a-z]/);

  if (!regexSmallCase.test(control.value) && control.value) {
    return { hasSmallCase: true };
  }
  return null;
}

export function ValidateOnlyText(control: AbstractControl) {
  const onlyText: RegExp = /^[A-Za-zÀ-ÿ\s]+$/;

  if (!onlyText.test(control.value) && control.value) {
    return { onlyText: true };
  }
  return null;
}

export function ValidatehasSpecialCharacters(control: AbstractControl) {
  const regexhasSpecialCharacters: RegExp = new RegExp(
    /[!@#$%^&*(),.?":{}|<>]/
  );

  if (!regexhasSpecialCharacters.test(control.value) && control.value) {
    return { hasSpecialCharacters: true };
  }
  return null;
}

export function noWhiteSpaceValidator(control: AbstractControl) {
  const regexHasWhiteSpace: RegExp = new RegExp(/[^\S\r\n]/);
  if (regexHasWhiteSpace.test(control.value) && control.value) {
    return { whiteSpace: true };
  }
  return null;
}

export function ValidateCustomEmail(control: AbstractControl) {
  const regexHasValidEmail: RegExp = new RegExp(
    /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/
  );

  if (!regexHasValidEmail.test(control.value) && control.value) {
    return { hasValidEmail: true };
  }
  return null;
}

export function ValidateCustomCep(control: AbstractControl) {
  const cep = control.value;
  if (cep && cep !== '') {
    const regexValidateCep = /^[0-9]{8}$/;
    return regexValidateCep.test(cep) ? null : { hasValidCep: true };
  }
  return null;
}

export function ValidateCustomCpf(control: AbstractControl) {
  const cpf = control.value;
  if (cpf) {
    let numbers, digits, sum, i, result, equalDigits;
    equalDigits = 1;
    if (cpf.length < 11) {
      return null;
    }

    for (i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
        equalDigits = 0;
        break;
      }
    }

    if (!equalDigits) {
      numbers = cpf.substring(0, 9);
      digits = cpf.substring(9);
      sum = 0;
      for (i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (result !== Number(digits.charAt(0))) {
        return { validCPF: true };
      }
      numbers = cpf.substring(0, 10);
      sum = 0;

      for (i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (result !== Number(digits.charAt(1))) {
        return { validCPF: true };
      }
      return null;
    } else {
      return { validCPF: true };
    }
  }
  return null;
}

export function ValidateCustomPhone(control: AbstractControl) {
  const regexHasValidPhone: RegExp = new RegExp(
    /(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))/
  );

  if (!regexHasValidPhone.test(control.value) && control.value) {
    return { hasValidPhone: true };
  }
  return null;
}

export function ValidateCustomDdd(control: AbstractControl) {
  const dddList = [
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '21',
    '22',
    '24',
    '27',
    '28',
    '31',
    '32',
    '33',
    '34',
    '35',
    '37',
    '38',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '51',
    '53',
    '54',
    '55',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '71',
    '73',
    '74',
    '75',
    '77',
    '79',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99'
  ];

  const phone: string = control.value;
  const ddd = phone?.substring(0, 2);

  if (phone?.length >= 10 && ddd && ddd?.length >= 2) {
    const isValid = dddList.find((x) => x === ddd);
    if (!isValid) {
      return { hasValidDdd: true };
    }
  }

  return null;
}

export function ValidateCustomCnpj(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  if (CustomValidators.validateCNPJ(control.value)) {
    return null;
  }
  return {
    validCNPJ: true
  };
}
