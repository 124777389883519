<div *ngIf="modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS'">
  <div *ngIf="!notificationClosed" class="modal-pwa shadow">
    <div *ngIf="modalPwaPlatform === 'ANDROID'" class="notification-message">
      <img
        class="icon"
        src="../assets/icons/icon-pv-pwa-72.png"
        alt="ícone premiervet"
      />
      <div class="message">
        <h5 class="modal-pwa-title">Instalar PremieRvet<span>®</span></h5>
        <p class="modal-pwa-url">app.premiervet.com.br</p>
      </div>
      <button (click)="addToHomeScreen()" class="modal-pwa-button">
        Instalar
      </button>
    </div>
    <div *ngIf="modalPwaPlatform === 'IOS'" class="notification-message">
      <div class="pvet-logo logo-ios">
        <img
          class="icon"
          src="../assets/icons/icon-pv-pwa-72.png"
          alt="ícone premiervet"
        />
        <div class="message">
          Para adicionar o atalho do PremieRvet®, clique no "Menu"
          <img
            src="https://res.cloudinary.com/rodrigokamada/image/upload/v1641089482/Blog/angular-pwa/safari_action_button_38x50.png"
            class="ios-menu m-0"
            alt="safari_action_button_38x50.png"
          />
          selecione a opção "Adicione à tela de início"
          <i class="bi bi-plus-square"></i>
        </div>
      </div>
    </div>
  </div>
</div>
