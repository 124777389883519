import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class Ga4Service {
  constructor() {}

  emit(event: string, id?: string): void {
    try {
      console.log('ga4 emit:', event);
      // @ts-expect-error
      window.dataLayer.push({
        event,
        gtm: {
          _event: event,
          Event: event,
          Id: id
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}
