import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private pwa: BehaviorSubject<any> = new BehaviorSubject<any>('');
  private isConfirmOpen = false;
  constructor(
    private swUpdate: SwUpdate,
    private zone: NgZone
  ) {}
  public verifyUpdate(): void {
    console.log('PWA 1: ', this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) {
      this.zone.runOutsideAngular(() => {
        console.log('PWA 2: check update outside angular');
        this.swUpdate.checkForUpdate().then((update) => {
          console.log('PWA 3: Update is already', update);
          if (update) {
            this.swUpdate.versionUpdates.subscribe((evt) => {
              console.log(
                `Enable: ${this.swUpdate.isEnabled} | Update: ${update} | Evt: ${evt.type}`
              );
              this.openConfirmDialog().then((resp) => {
                if (resp) {
                  window.location.reload();
                }
              });
            });
          }
        });
      });
    }
  }

  openConfirmDialog(): Promise<boolean> {
    if (this.isConfirmOpen) {
      console.log('A confirm dialog is already open.');
      return Promise.resolve(false);
    }
    this.isConfirmOpen = true;
    return new Promise((resolve) => {
      const result = window.confirm(
        'Nova versão do PremieRvet disponível! Atualize e aproveite as novas funcionalidades..'
      );
      this.isConfirmOpen = false;
      resolve(result);
    });
  }

  getDownloadPwaEvent(): BehaviorSubject<any> {
    return this.pwa;
  }

  setDownloadPwaEvent(event: any): void {
    this.pwa.next(event);
  }
}
