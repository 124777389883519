import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrl: './upload-image.component.scss'
})
export class UploadImageComponent {
  @ViewChild('inputUpload') upload: ElementRef;
  @Input() label = 'Adicionar imagem';
  @Input() imageUrl: string;
  @Input() maxFileSizeMB = 5;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() acceptedFiles: string = '.jpg, .jpeg, .png, .webp';
  error = false;

  @Output() selectedImageFile = new EventEmitter<File>();
  @Output() selectedImageUrl = new EventEmitter<string>();

  selectFile(): void {
    this.upload.nativeElement.click();
  }

  uploadFile(event: Event): void {
    this.error = false;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];

      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > this.maxFileSizeMB) {
        this.error = true;
        return;
      }

      this.convertFileToBase64(file)
        .then((base64String) => {
          this.imageUrl = base64String;
          this.selectedImageFile.emit(file);
          this.selectedImageUrl.emit(base64String);
          input.value = null;
        })
        .catch((error) => {
          console.error('Erro ao converter o arquivo:', error);
        });
    }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  removeLogo(): void {
    this.imageUrl = null;
    this.selectedImageFile.emit(null);
    this.selectedImageUrl.emit(null);
  }
}
