import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateRangeService {
  selectedRangeStartDate: Date | null = null;
  selectedRangeEndDate: Date | null = null;

  constructor() {}

  setRange(startDate: Date, endDate: Date): void {
    this.selectedRangeStartDate = startDate;
    this.selectedRangeEndDate = endDate;
  }

  clearRange(): void {
    this.selectedRangeStartDate = null;
    this.selectedRangeEndDate = null;
  }
}
