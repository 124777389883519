export class MonthlyProgressDto {
  dateFormatted: string;
  weight: string;
  monthlyWeightLoss: string;
  realWeightLoss: string;
  gramsPerDay: string;
  weightFormatted: string;
  realWeightLossFormatted: string;
  nextReturn: boolean;
  currentOrientation: boolean;
  firstObesityTreatment: boolean;

  constructor(
    dateFormatted: string,
    weight: number,
    monthlyWeightLoss: number,
    gramsPerDay: number,
    nextReturn: boolean,
    currentOrientation: boolean,
    firstObesityTreatment: boolean,
    realWeightLoss?: number
  ) {
    this.dateFormatted = dateFormatted;
    this.weight = `${weight.toFixed(3)} kg`;
    this.weightFormatted = this.weight.replace(/\./g, ',');
    this.monthlyWeightLoss = `${monthlyWeightLoss.toFixed(3)} kg`;
    this.monthlyWeightLoss = this.monthlyWeightLoss.replace(/\./g, ',');

    this.gramsPerDay = `${gramsPerDay} g`;
    this.gramsPerDay = this.gramsPerDay.replace(/\./g, ',');
    this.nextReturn = nextReturn;
    this.currentOrientation = currentOrientation;
    this.firstObesityTreatment = firstObesityTreatment;
    if (realWeightLoss) {
      this.realWeightLoss = `${realWeightLoss.toFixed(3)} kg`;
      if (this.realWeightLoss !== '0.000 kg') {
        this.realWeightLoss = !this.realWeightLoss.includes('-')
          ? '+' + this.realWeightLoss
          : this.realWeightLoss;
        this.realWeightLoss = this.realWeightLoss.replace('-', '- ');
        this.realWeightLoss = this.realWeightLoss.replace('+', '+ ');
        this.realWeightLoss = this.realWeightLoss.replace(/\./g, ',');
      }
      return;
    }

    if (firstObesityTreatment) {
      this.realWeightLoss = 'Inicio';
      this.monthlyWeightLoss = '-';
      return;
    }

    this.realWeightLoss = `-`;
    return;
  }
}
