import { GenderEnum } from '../../enums/gender.enum';
import { SpecieEnum } from '../../enums/specie.enum';
import { CalculatorTypeEnum } from '../../../nutritional-calculator/models/calculator-type.enum';

export class RadioButtonModel {
  id: unknown;
  icon?: string;
  description: string;
  disabled?: boolean;
}

export const optionsCalculation: RadioButtonModel[] = [
  {
    id: CalculatorTypeEnum.PER_PET,
    icon: '../../../../../assets/icons/icon_pet.svg',
    description: 'Calcular a partir dos dados do pet'
  },
  {
    id: CalculatorTypeEnum.PER_FOOD,
    icon: '../../../../../assets/icons/icon_food.svg',
    description: 'Calcular a partir de um alimento'
  }
];

export const optionsSpecie: RadioButtonModel[] = [
  {
    id: SpecieEnum.DOG,
    icon: '../../../../../assets/icons/icon_dog.svg',
    description: 'Cão'
  },
  {
    id: SpecieEnum.CAT,
    icon: '../../../../../assets/icons/icon_cat.svg',
    description: 'Gato'
  }
];

export const optionsGender: RadioButtonModel[] = [
  {
    id: GenderEnum.MALE,
    icon: '../../../../../assets/icons/icon_gender-male.svg',
    description: 'Macho'
  },
  {
    id: GenderEnum.FEMALE,
    icon: '../../../../../assets/icons/icon_gender-female.svg',
    description: 'Fêmea'
  }
];

export const optionsDefault: RadioButtonModel[] = [
  {
    id: true,
    description: 'Sim'
  },
  {
    id: false,
    description: 'Não'
  }
];
