import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalculatorModel } from './models/calculator-model';
import { CalculatorProductsModel } from './models/calculator-products-model';
import { LoadingService } from '../shared/loading.service';
import { SpecieEnum } from '../../modules/shared/enums/specie.enum';
import { AgeGroupEnum } from '../../modules/shared/enums/age-group.enum';
import { SizeEnum } from '../../modules/shared/enums/size.enum';
import { CalculatorEnhancedModel } from './models/calculator-enhanced-model';
import { CalculatorProductsResultModel } from './models/calculator-product-result-model';
import { OrientationPurpose } from './models/calculator-clinical-characteristics-model';
import { GenderEnum } from 'src/app/modules/shared/enums/gender.enum';
import { BreedEnum } from '../../modules/shared/enums/breed.enum';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  apiUrl = `${environment.baseApimUrl}/calculadora`;
  apiCalculator = `${environment.apiCalculator}/calculator`;

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  getAlimentation(
    calculator: CalculatorModel
  ): Observable<CalculatorProductsModel> {
    const headers = this.loadingService.getLoadingHeader();
    const url = `${this.apiUrl}/alimentos`;
    return this.http.post<CalculatorProductsModel>(url, calculator, {
      headers
    });
  }

  getPetAgeGroup(
    specie: SpecieEnum,
    size: SizeEnum,
    birthDate: string
  ): Observable<AgeGroupEnum> {
    const url = `${this.apiUrl}/petagegroup/specie/${specie}/size/${size}/birthDate/${birthDate}`;
    return this.http.get<AgeGroupEnum>(url);
  }
  getProducts(
    calculator: CalculatorEnhancedModel,
    showLoading: boolean = false
  ): Observable<CalculatorProductsResultModel> {
    let headers = new HttpHeaders();
    if (showLoading) {
      headers = this.loadingService.getLoadingHeader();
    }
    return this.http.post<CalculatorProductsResultModel>(
      `${this.apiCalculator}/products`,
      calculator,
      {
        headers
      }
    );
  }

  getClinicalCharacteristcs(
    specie: SpecieEnum,
    breed: BreedEnum
  ): Observable<OrientationPurpose[]> {
    const url = `${this.apiCalculator}/purposes/specie/${specie}/breed/${breed}`;
    return this.http.get<OrientationPurpose[]>(url);
  }

  filterClinicalCaracteristcs(
    gender: GenderEnum,
    list: OrientationPurpose[]
  ): OrientationPurpose[] {
    return list.filter(
      (x) =>
        (gender == GenderEnum.MALE &&
          x.name != 'Gestante' &&
          x.name != 'Lactante') ||
        gender != GenderEnum.MALE
    );
  }

  getOrientationClinicalCharacteristcs(
    specie: SpecieEnum,
    petId: number,
    breed: BreedEnum,
    now: Date
  ): Observable<OrientationPurpose[]> {
    const url = `${this.apiCalculator}/purposes/specie/${specie}/pet/${petId}/breed/${breed}/${now.getTime() / 1000}`;
    return this.http.get<OrientationPurpose[]>(url);
  }
}
