import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-new-breadcrumbs',
  templateUrl: './new-breadcrumbs.component.html',
  styleUrls: ['./new-breadcrumbs.component.scss']
})
export class NewBreadcrumbsComponent {
  @Input() breadcrumbs: Breadcrumb[];
  constructor() {}
}

export class Breadcrumb {
  name: string;
  icon: string;
  url: string;
  last: boolean;
}
