<div class="container">
  <img
    [src]="imgBackground"
    class="img-background"
    alt="imagem ilustrativa de página não encontrada"
  />

  <container-element [ngSwitch]="expiredToken">
    <p class="description" *ngSwitchCase="true">
      A página que você está tentando acessar já expirou :(
    </p>

    <p class="description" *ngSwitchDefault>
      Desculpe, a página que você está tentando acessar não existe ou você está
      com o PremieRvet® desatualizado.
    </p>
  </container-element>
  <button (click)="getHome()" class="button button-primary">
    Volte ao PremieRvet
  </button>
</div>
