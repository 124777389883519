<div class="container-filter">
  <div class="filter-title">
    <p>Filtros</p>
    <button
      [disabled]="countFiltersActive === 0"
      class="btn clear"
      (click)="removeFilters()"
    >
      <i class="bi bi-trash"></i>
      <span>Limpar</span>
    </button>
  </div>
  <div *ngFor="let item of checkboxes; let i = index">
    <label [for]="i"> {{ item.title }} </label>
    <input
      type="checkbox"
      [id]="i"
      [(ngModel)]="item.selected"
      value="{{ item.value }}"
      [checked]="item.selected"
      class="input-checkbox outline-primary"
      (change)="
        item.type === 'specie' ? getSpecies($event) : getGenders($event)
      "
    />
  </div>
</div>
<hr />
<div class="footer">
  <button class="btn-close" (click)="onCloseModal()">Fechar</button>
</div>
