<div class="calendar-grid">
  <table class="calendar-table">
    <tr>
      <th>D</th>
      <th>S</th>
      <th>T</th>
      <th>Q</th>
      <th>Q</th>
      <th>S</th>
      <th>S</th>
    </tr>
    <tr *ngFor="let weekDays of renderCalendarGrid()">
      <td
        *ngFor="let date of weekDays; let colIndex = index"
        [class.selected]="date && isDateSelected(date)"
        [class.current-day]="
          date && isCurrentDay(date) && !selectedRangeStartDate
        "
        [class.future-date]="date && isFutureDate(date)"
        [class.range]="date && isDateInRange(date)"
        [class.selected-range-start]="
          selectedRangeStartDate &&
          date &&
          selectedRangeStartDate.toDateString() === date.toDateString()
        "
        [class.selected-range-end]="
          selectedRangeEndDate &&
          date &&
          selectedRangeEndDate.toDateString() === date.toDateString()
        "
        [class.selected-range]="date && isDateInSelectedRange(date)"
        [ngClass]="{
          'first-of-line': colIndex === 0,
          'last-of-line': colIndex === weekDays.length - 1
        }"
        (click)="date && onSelectDate(date)"
      >
        {{ date ? date.getDate() : '' }}
      </td>
    </tr>
  </table>
</div>
