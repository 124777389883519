import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TermsResponsabilityService } from '../../../../services/terms-responsability/terms-responsability.service';
import { OrientationService } from '../../../../services/orientation/orientation.service';
import { ActivatedRoute } from '@angular/router';
import { FilesService } from '../../../../services/fileService/files.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { AcceptTermsRequest } from '../../../../services/terms-responsability/models/accept-terms-request';
import { AcceptedTermsResponse } from '../../../../services/terms-responsability/models/accepted-terms-response';

@Component({
  selector: 'app-download-guidance',
  templateUrl: './download-guidance.component.html',
  styleUrls: ['./download-guidance.component.scss']
})
export class DownloadGuidanceComponent implements OnInit {
  termsForm: UntypedFormGroup;
  termsPdfLink: string = environment.urlTermsPdf;
  accepted = true;
  checked = false;
  urlPremierPet = environment.urlwhereBuyPremier;
  descriptionLabel: string;
  orientationCode: string;
  email: string;
  phone: string;
  pet: string;
  terms: AcceptedTermsResponse;
  enableButton = false;
  whatsappLink = false;

  constructor(
    private fb: UntypedFormBuilder,
    private termsService: TermsResponsabilityService,
    private orientationService: OrientationService,
    private route: ActivatedRoute,
    private fileService: FilesService,
    private notificationService: NotificationService
  ) {
    this.termsForm = fb.group({
      acceptedTerms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.whatsappLink = this.route.snapshot.queryParams.whatsapp;
    this.orientationCode = this.route.snapshot.paramMap.get('id');
    this.email = this.route.snapshot.paramMap.get('email');
    this.phone = this.route.snapshot.paramMap.get('phone');
    this.pet = this.route.snapshot.paramMap.get('pet');
    this.termsService
      .getAcceptedTerms(this.email, this.phone)
      .subscribe((resp) => {
        this.terms = resp;
        if (this.terms.termsAccepted === true) {
          this.descriptionLabel =
            'A orientação nutricional do seu pet está pronta. Para baixar é só clicar no botão abaixo.';
          this.checked = this.terms.termsAccepted;
          this.enableButton = true;
          return;
        }
        this.descriptionLabel =
          'Para baixar a orientação criada para seu pet, é necessário aceitar os termos e as politicas de privacidade.';
        this.checked = false;
        this.enableButton = true;
      });
  }

  handleSelected($event: any): void {
    const request = new AcceptTermsRequest();
    request.email = this.email === 'no-email' ? null : this.email;
    request.phone = this.phone === 'no-phone' ? null : this.phone;
    if ($event.target.checked === true) {
      this.termsForm.controls.acceptedTerms.setValue(true);
      request.acceptTerms = true;
      this.accepted = true;
    } else {
      request.acceptTerms = false;
      this.accepted = false;
    }
    this.termsService.postAcceptTerms(request).subscribe((resp) => {
      this.termsService
        .getAcceptedTerms(this.email, this.phone)
        .subscribe((r) => {
          this.terms = r;
          this.accepted = this.terms.termsAccepted;
        });
    });
  }

  submit(): void {
    if (this.terms.termsAccepted) {
      this.orientationService
        .downloadOrientation(
          this.orientationCode,
          this.email,
          this.whatsappLink
        )
        .then((resp) => {
          this.fileService
            .convertAndDownloadPdf(
              resp,
              `${this.pet}_${this.orientationCode}.pdf`
            )
            .then(async (response) => {
              await new Promise((r) => setTimeout(r, 5000));
              window.location.href = this.urlPremierPet;
            });
        })
        .catch((err) => {
          this.notificationService.error(
            `A orientação nutricional ${this.orientationCode} não foi encontrada.`
          );
        });
    } else {
      this.accepted = false;
    }
  }
}
