<ng-template #desktop>
  <div class="desktop-rum-modal-container" (click)="$event.stopPropagation()">
    <div class="rum-modal-header">
      <h4 class="modal-title">Atualize seu cadastro</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Fechar"
        (click)="closeModal()"
      >
        <i class="bi bi-x icon__close"></i>
      </button>
    </div>
    <div class="rum-modal-body">
      <p>
        Parabéns pela sua formatura! Para atualizar seu perfil para
        médico-veterinário, por favor, informe seu número do CRMV e a UF.
      </p>
      <form [formGroup]="crmvForm" autocomplete="off">
        <field-validate
          fieldName="CRMV"
          [showSuccessBackground]="false"
          [customErrorMessage]="'Preenchimento obrigatório'"
        >
          <input
            numbersOnly
            type="text"
            placeholder="CRMV"
            formControlName="numeroCrmv"
            placeholder="00000"
            maxlength="5"
            minlength="4"
          />
        </field-validate>
        <field-validate
          [showAsterisk]="false"
          fieldName="UF"
          for="state-crmv"
          class="uf__field"
        >
          <select id="state-crmv" formControlName="estadoCrmv">
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let state of listStates" [value]="state.name">
              {{ state.name }}
            </option>
          </select>
        </field-validate>
      </form>
      <div class="alert-card">
        <p>
          A PremieRvet® é uma plataforma de uso de médicos-veterinários para
          fins de orientação nutricional sob sua exclusiva responsabilidade.
        </p>
      </div>
    </div>
    <div class="rum-modal-footer">
      <button
        class="button button-link"
        (click)="closeModal()"
        [disabled]="isLoading"
      >
        Cancelar
      </button>
      <button
        class="button button-primary"
        (click)="onSubmit()"
        [disabled]="isLoading"
      >
        <span>Salvar alteração</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #mobile>
  <div class="mobile-rum-modal-container" (click)="$event.stopPropagation()">
    <div class="rum-modal-header">
      <h4 class="modal-title">Atualize seu cadastro</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Fechar"
        (click)="closeModal()"
      >
        <i class="bi bi-x icon__close"></i>
      </button>
    </div>
    <div class="rum-modal-body">
      <img
        src="../../assets/icons/veterinarian-1.svg"
        class="img-mobile"
        alt="Símbolo de mais com uma patinha de cachorro no centro, remetendo a cuidados veterinários"
      />
      <p>
        Parabéns pela sua formatura! Para atualizar seu perfil para
        médico-veterinário, por favor, informe seu número do CRMV e a UF.
      </p>
      <form [formGroup]="crmvForm" autocomplete="off">
        <field-validate
          fieldName="CRMV"
          [showSuccessBackground]="false"
          [customErrorMessage]="'Preenchimento obrigatório'"
        >
          <input
            numbersOnly
            type="text"
            placeholder="CRMV"
            formControlName="numeroCrmv"
            placeholder="00000"
            maxlength="5"
            minlength="4"
          />
        </field-validate>
        <field-validate
          [showAsterisk]="false"
          fieldName="UF"
          for="state-crmv"
          class="uf__field"
        >
          <select id="state-crmv" formControlName="estadoCrmv">
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let state of listStates" [value]="state.name">
              {{ state.name }}
            </option>
          </select>
        </field-validate>
      </form>
      <div class="alert-card">
        <p>
          A PremieRvet® é uma plataforma de uso de médicos-veterinários para
          fins de orientação nutricional sob sua exclusiva responsabilidade.
        </p>
      </div>
    </div>
    <div class="rum-modal-footer">
      <button
        class="button button-primary"
        (click)="onSubmit()"
        [disabled]="isLoading"
      >
        <span>Salvar alteração</span>
      </button>
    </div>
  </div>
</ng-template>
